import React, { useState } from 'react'
import { Link } from 'gatsby'
import posed, { PoseGroup } from 'react-pose'
import nav from '../styles/navegacion.module.scss'
import logo from '../assets/logo INNOVA.png'


const Lista = posed.div({
  enter: {
    opacity: 1,
    delay: 200
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 10
    }
  }
});
const Separador = posed.div({
    init: {
      height: '10px'
    },
    expandir: {
      height: '30px',
      transition: { type: 'spring', stiffness: 100, duration: 200}
    },
    contraer: {
      height: '10px',
      delay: 10,
      transition: {
        type: 'keyframes',
        values: ['30px', '10px'],
        times: [0, 1],
        duration: 200
      }
    }
  });
const MenuRes = posed.div({
  enter: {
   opacity: 1
  },
  exit: {
    opacity: 0
  }
});
const Drop = ({esVisible}) => {
  return (
    <PoseGroup>
      {esVisible === true &&
        <Lista key="lista" className={nav.dropdowncont}>
          <Link to="/servicios" activeStyle={{background: '#ffffff', color: '#AD1313'}}>Servicios</Link>
          <Link to="/servicios-especiales" activeStyle={{background: '#ffffff', color: '#AD1313'}}>Servicios Especiales</Link>
          <Link to="/alianzas" activeStyle={{background: '#ffffff', color: '#AD1313'}}>Alianzas</Link>
        </Lista>
      }
    </PoseGroup>
  )
}
const ContMenuRes = ({esVisible}) => {
  return (
    <PoseGroup>
      {esVisible === true &&
      <MenuRes key="menu" className={nav.menures}>
        <Link to="/" activeStyle={{background: '#ffffff', color: '#AD1313'}}>INICIO</Link>
        <Link to="/acerca-de-innovamd" activeStyle={{background: '#ffffff', color: '#AD1313'}}>ACERCA DE</Link>
        <Link to="/politicas" activeStyle={{background: '#ffffff', color: '#AD1313'}}>POLITICAS</Link>
        <Link to="/servicios" activeStyle={{background: '#ffffff', color: '#AD1313'}}>SERVICIOS</Link>
        <Link to="/servicios-especiales" activeStyle={{background: '#ffffff', color: '#AD1313'}}>SERVICIOS ESPECIALES</Link>
        <Link to="/alianzas" activeStyle={{background: '#ffffff', color: '#AD1313'}}>ALIANZAS</Link>
        <Link to="/#contactar">CONTACTO</Link>
      </MenuRes>
      }
    </PoseGroup>
  )
}

const Navegacion = () => {
  function clickMenu(e) {
    e.preventDefault();
    setExpandir(!expandir);
  }
  function tapMenu(e) {
    e.preventDefault();
    setMenu(!menuRes);
  }

  const [menuRes, setMenu] = useState(false);
  const [expandir, setExpandir] = useState(false);

  return(
    <nav className={nav.raiz}>
      <Separador className={nav.separador} pose={expandir ? 'expandir': 'contraer'}>
        {/*expandir && <ul className={`${nav.dropdown} ${expandir ? nav.expandir : nav.contraer}`}>
          <Link to="/servicios">Servicios</Link>
          <Link to="/servicios-especiales">Servicios Especiales</Link>
          <Link to="/alianzas">Alianzas</Link>
        </ul>*/}
        <Drop esVisible={expandir}/>
      </Separador>
      <div className={nav.barra}>
        <div className={nav.branding}>
          <img src={logo} alt={"Logotipo de Innova MD"}/>
        </div>
        <ul className={nav.links}>
          <Link to="/" className={nav.link} activeStyle={{color: '#1F497D'}}>INICIO</Link>
          <Link to="/acerca-de-innovamd" className={nav.link} activeStyle={{color: '#1F497D'}}>ACERCA DE</Link>
          <Link to="/politicas" className={nav.link} activeStyle={{color: '#1F497D'}}>POLITICAS</Link>
          <div className={nav.link} onClick={clickMenu}>SERVICIOS {(!expandir && '▼')}{(expandir && '▲')}</div>
          <Link to="/#contactar" className={nav.link}>CONTACTO</Link>
        </ul>
        <ContMenuRes esVisible={menuRes}/>
        <svg className={nav.boton} onClick={tapMenu} style={{ padding: '6px'}}xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z" className={nav.abrirmenu}/></svg>
      </div>
    </nav>

  );
}

export default Navegacion;