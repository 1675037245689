import React from 'react'
import footer from '../styles/pie.module.scss'
import logo from '../assets/logo INNOVA.png'
import facebook from '../assets/ico/facebook.png'
import linkedin from '../assets/ico/linkedin.png'

const Navegacion = () => (
  <footer className={footer.raiz}>
    <div className={footer.separador}/>
    <div className={footer.principal}>
      <div className={footer.branding}>
        <img src={logo} alt={"Logotipo de Innova MD"}/>
      </div>
      <div className={footer.links}>
        <span>INNOVA M&D ©{new Date().getFullYear()}</span>
        <span className={footer.social}>
          <a target={"blank"} href={"https://www.facebook.com/INNOVAMDCONSULTING/"}> <img src={facebook} alt={"Facebook"}/></a>
          <a target={"blank"} href={"https://www.linkedin.com/company/innova-md/"}> <img src={linkedin} alt={"LinkedIn"}/></a>
        </span>
      </div>
    </div>
  </footer>

);

export default Navegacion;
