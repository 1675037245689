/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Navegacion from '../components/navegacion'
import Pie from '../components/pie'

import '../components/layout.css'
import layout from '../styles/layout.module.scss'

const Layout = ({ children }) => {

  return (
    <>
      <Navegacion/>
      <div className={layout.cuerpo}>
        { children }
      </div>
      <Pie/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
